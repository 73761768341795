<template>
    <div class="">

        <div class="">
            <div class="page-banner">
                <img src="/dist/img/coach_banner.png" style="width:100%;" alt="page-banner">
                <div class="page-title">
                    <h5 class="text-white">Club Insurance Information</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 mb-3 mt-4">
                  <b-overlay :show="loading" no-wrap></b-overlay>
                  <custom-alert v-if="displayAlert==true" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
                    <div class="card card-body pt-0">
                        <p class="mt-3 ">As an USA Water Ski & Wake Sports affiliated club, there are several insurance
                            benefits at your disposal, including optional additional club insurance, directors and
                            officers insurance, coaches liability insurance and drone insurance.</p>
                        <a target="_blank" href="https://www.teamusa.org/usa-water-ski/insurance">See a full list of
                            insurance resources</a>
                        <p class="mt-3 ">If you would like to get your club added as an additional insured to the USA-WSWS policy and
                            receive a certificate of insurance with both entities listed, please apply below:</p>
                        <ul>
                            <li><a :href="insuranceUrl" :class="[expired?'text-gray':'text-blue']" @click="checkExpires();" :disabled="[expired?true:false]" :title="alertMessage">Purchase Certificate of Insurance <template v-if="current_insurance_fee">(${{ current_insurance_fee }})</template></a></li>
                        </ul>

                        <p>Occasionally, clubs are also asked to show a USA Water Ski &amp; Wake Sports certificate of
                              insurance.</p>
                        <template v-if="certOfInsurence && certOfInsurence.id">
                          <ul>
                              <li>
                                <a href="javascript:void(0);" class="text-blue" @click="downloadCertificate">
                                  View USA-WSWS Certificate of Insurance (PDF) <i v-if="downloading" class="fa fa-circle-notch fa-spin ml-3 text-dark"></i>
                                </a>
                              </li>
                          </ul>
                        </template>
                    </div>
                </div>
                <div class="col-md-4 status_bar mt-4">
                    <div class="right-sidebarlink">
                        <ul class="sidebarlink">
                            <li>
                                <router-link class="" to="/club/club-insurance-information">
                                    <div class="card mb-2">
                                        <div class="card-header rm-border bggrdient">
                                            <h3 class="text-white"><i class="far fa-address-card"></i> Club Insurance
                                                Information </h3>
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                            <li>
                                <router-link class="" to="/club/quick-link-resources">

                                    <div class="card mb-2">
                                        <div class="card-header rm-border bggrdient">
                                            <h3 class="text-white"><i class="fas fa-dharmachakra"></i> Quick Link
                                                Resources</h3>
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                            <li>
                                <a target="_blank" class="" href="https://www.teamusa.org/usa-water-ski/safe-sport">
                                    <div class="card mb-2">
                                        <div class="card-header rm-border bggrdient">
                                            <h3 class="text-white"><i class="fas fa-dharmachakra"></i> Athlete
                                                Safety/Safe Sport</h3>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <router-link class="" to="/club/practice-event-sanctioning">
                                    <div class="card mb-2">
                                        <div class="card-header rm-border bggrdient">
                                            <h3 class="text-white"><i class="fas fa-dharmachakra"></i> Practice and
                                                Event Sanctioning</h3>
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                            <li>
                                <router-link class="" to="/club/club-event-merchandise">
                                    <div class="card mb-2">
                                        <div class="card-header rm-border bggrdient">
                                            <h3 class="text-white"><i class="fas fa-tags"></i> Club/Event Merchandise
                                            </h3>
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                            <li>
                                <router-link class="" to="/club/award-trophy-discounts">
                                    <div class="card mb-2">
                                        <div class="card-header rm-border bggrdient">
                                            <h3 class="text-white"><i class="fas fa-tags"></i> Award/Trophy Discounts
                                            </h3>
                                        </div>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
import CustomAlert from "./../../CustomAlert";
import moment from "moment";
import axios from "axios";

export default {
    name: "clubInsurance",
    components: {CustomAlert},
    data() {
        return {
            loading: false,
            expiryDate: "",
            clubInfo:[],
            currentDate:"",
            insuranceUrl:"javascript:void(0);",
            certOfInsurence:"",
            alertMessage: "",
            displayAlert: "",
            insurenceAlertMsg:"",
            expired: false,
            current_insurance_fee: 0,
            current_sanction_fee: 0,
            downloading: false,
        }
    },
    methods:{   
        date_function: function () { 
           this.currentDate = new Date().toJSON().slice(0,10).replace(/-/g,'/');            
        },
      checkExpires() {
        if(this.expired) {
          this.alertMessage = "Please renew club membership!";
          this.insurenceAlertMsg= "Please renew their club membership to access the current certificate of insurance!";
          this.displayAlert = true;
        } else {
          this.alertMessage = "Please wait for a while!";
          this.insurenceAlertMsg= "Please wait for a while!";
          this.displayAlert = true;
        }
      },
      loadClubInfo() {
        this.loading = true;
        axios
          .get(this.basePath + "api/club/user_verify", { headers: this.clubHeaders })
          .then((response) => {
            this.expired = false;
            const resp = response.data.data;
            this.current_insurance_fee = resp.current_insurance_fee;
            let year = moment(resp.valid_thru).format('YYYY');
            if(moment(new Date()).isAfter(moment(new Date(resp.valid_thru)))) {
              this.alertMessage = "Please renew club membership!";
              this.insurenceAlertMsg= "Please renew club membership"
              this.displayAlert = true;
              this.expired = true;
              this.insuranceUrl = "javascript:void(0);";
            }else if(moment(new Date()).isAfter(moment(resp.valid_thru).format('YYYY')+'-10-31')){
              this.alertMessage = "Please renew club membership for "+(parseInt(year)+1)+"!";
              this.insurenceAlertMsg= "Please renew club membership for "+(parseInt(year)+1)+"";
              this.displayAlert = true;
              this.expired = true;
              this.insuranceUrl = "javascript:void(0);";
            } else {
              this.insuranceUrl = "/club/purchase-insurance";
            }
            if(this.current_insurance_fee === 0 || isNaN(this.current_insurance_fee)) this.insuranceUrl = "javascript:void(0);";
            this.loadCertificate(year);
          })
          .catch((error) => {
            console.log(error);
          });
      },
      loadCertificate(year) {
        axios.get(this.basePath+"api/club-insurance-certificates/retrieve/" + year, { headers: this.clubHeaders })
        .then(res => {
          console.log(res);
          if (res.data.status === "success") {
            this.certOfInsurence = res?.data?.data;
          }
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        });
      },
      downloadCertificate() {
        this.downloading = true;
        axios.get(
            this.basePath+"api/club-insurance-certificates/download/"+this.certOfInsurence?.id,
            { headers: this.clubHeaders, responseType: 'blob' }
        )
        .then(response => {
          const link = document.createElement('a');
          const blob = new Blob([response.data]);
          link.href = window.URL.createObjectURL(blob);
          link.download = "club_insurance_certificate-"+this.certOfInsurence?.year+".pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.downloading = false;
        })
        .catch(err => console.log(err));
      }
    },
    mounted() {
        this.loadClubInfo();
        this.date_function()
    },
    computed: {
    years() {
    const year = new Date().getFullYear();
    return Array.from({ length: 8 }, (value, index) => year + index);
    },
  },

}
</script>
